import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Accessing our API"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`If you’ve been waiting to integrate enableHR with another software system, and you have a developer handy, they’ll be able to take our API and build you a custom connector.`}</p>
    <p>{`To access our API, please contact us at `}<a parentName="p" {...{
        "href": "mailto:api@enablehr.com"
      }}>{`api@enablehr.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      